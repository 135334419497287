import React from "react";
import "./about.css";
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation();
  return (
    <div className="about-container ">
      <div className="inner-container">
        <h1>{t("about_section.title")}</h1>
        <p className="text">{t("about_section.desc")}</p>
      </div>
    </div>
  );
};

export default About;
