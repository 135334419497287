import React from "react";
import "./languageselector.css";
import { useTranslation } from "react-i18next";

const LanguageSelectionBox = () => {
  const lngs = {
    en: { nativeName: "English" },
    tr: { nativeName: "Türkçe" },
    de: { nativeName: "Deutsch" },
  };
  const { t, i18n } = useTranslation();
  const changeLang = (event) => {
    Object.keys(lngs).map((lng) => {
      if (lngs[lng].nativeName === event.target.value) {
        i18n.changeLanguage(lng);
      }
    });
  };
  return (
    <div className="selector">
      <select className="select" onChange={changeLang}>
        <option className="option" key="en">
          English
        </option>
        <option className="option" selected key="tr">
          Türkçe
        </option>
        <option className="option" key="de">
          Deutsch
        </option>
      </select>
    </div>
  );
};

export default LanguageSelectionBox;
