import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: "tr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          modal_title: "MEASUREMENTS and PROPERTIES",
          modal_button: "Close",
          company_slogan: "Trustworthy Products",
          navbar: {
            home: "Home",
            about_us: "About",
            products: "Products",
            works: "Our Services",
          },
          about_section: {
            title: "ABOUT US",
            desc: "Libertepack  started to serve as a manufacturer and supplier in the field of plastic packaging in 2018. The facility located in the ITOB organized industrial zone has a closed area of ​​1000 square meters and continues its activities. The company produces and supplies approximately 1000 tons of LPDE and HDPE plastic packaging annually. ",
          },
          footer: {
            contact_title: "Contact Us",
            navigation_title: "Navigation",
            location: `Fatih Mahallesi 1196 Sokak
            No: 3 Sarnıç / İZMİR / TURKEY`,
            footer_xd: "It is a subsidiary company of Inka Plastik.",
          },
          product_section: {
            title: "PRODUCTS",
            button: "View Details",
            products: [
              {
                title: "LDPE Film",
                desc: "Low Density Polyethylene (LDPE), is a kind of thermoplastic obtained from petroleum. It is flexible and transparent. LDPE FILM products have become one of the most preferred packaging materials thanks to the various advantages it offers.",
                product_img: require("./images/lpdeFlim.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `-Film Width: 20 cm - 300 cm`,
                  `-Film Thickness: 30 mic - 200 mic`,
                  `-It is water resistant.`,
                  `-Protects from moisture and dust.`,
                  "-It has a high thermal adhesion level.",
                ],
              },
              {
                title: "LDPE Shrink Film",
                desc: "Shrink films shrink tightly over whatever it covers when heat is applied. The heat can be applied with a handheld heat gun, or the product and film can pass through a heat tunnel on a conveyor.",
                product_img: require("./images/Shrink.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "Designed by upklyak / Freepik",
                details: [
                  `-Film Width: 20 cm - 300 cm`,
                  `-Film Thickness: 30 mic - 200 mic`,
                  `-The thickness of the film may vary depending on the weight of the load to be packaged, usage and storage
                  conditions.`,
                ],
              },
              {
                title: "Pallet Cover",
                desc: " It is used to protect all kinds of palletized products from the harmful effects of air such as sun, rain and dust. It is flexible and strong, wraps around sharp edges without tearing.",
                product_img: require("./images/palletcover.jpg"),
                product_img_alt:
                  "https://www.freepik.com/free-photo/creative-artisan-job-workshop_14315171.htm#query=Pallet%20coverd&position=18&from_view=search&track=ais%22%3EFreepik",
                product_img_alt_author: "Freepik",

                details: [
                  `It is produced from low density polyethylene.
                  (LDPE)`,
                  `With UV additive, high durability and long life in
                  storage are obtained.`,
                  `-Film Width: 50 cm - 300 cm`,
                  `-Film Thickness: 30mic - 200mic`,
                  `-Bellows: 5 cm – 70 cm`,
                ],
              },

              {
                title: "Garbage Bag",
                desc: "Protects the products against external weather conditions.",
                product_img: require("./images/garbagebag.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `We produce industrial garbage bags in
                  various sizes and colors.`,
                ],
              },
              {
                title: "Carrying Bag",
                desc: "Protects the products against external weather conditions.",
                product_img: require("./images/carrybag.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `We produce industrial carrying bags in various sizes and colors.`,
                ],
              },
              {
                title: "Mattress Bag",
                desc: "Slips over the mattress like a pillow case and can be sealed with tape.",
                product_img: require("./images/147.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "",
                details: [
                  `Protects your mattress from dust, dirt, bugs and spills while during transportation.`,
                  `It can be produced in accordance with gusseted perforated bags or automatic machines in 
                  accordance with the bed dimensions.`,
                ],
              },
              {
                title: "Industrial Bag",
                desc: `Industrial PE bags are preferred in many areas due to the special production of 
                the desired features and sizes according to the demand.`,
                product_img: require("./images/147.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "",
                details: [
                  `Industrial bag is used to protect the product wrapped inside against moisture and water.`,
                ],
              },
            ],
          },
        },
      },
      de: {
        translation: {
          modal_title: "MASSNAHMEN und EIGENSCHAFTEN",
          modal_button: "SCHLIEBEN",
          company_slogan: "Zuverlässige Produkte",
          navbar: {
            home: "Startseite",
            about_us: "Über uns",
            products: "Produkte",
            works: " Unsere Leistungen",
          },
          about_section: {
            title: "ÜBER UNS",
            desc: "Libertepack begann im Jahr 2018 als Hersteller und Lieferant im Bereich Kunststoffverpackungen. Die Anlage in der ITOB-Industriezone verfügt über eine geschlossene Fläche von 1000 Quadratmetern und setzt ihre Aktivitäten fort. Es produziert und liefert jährlich etwa 1000 Tonnen LPDE- und HDPE-Kunststoffverpackungen.",
          },
          footer: {
            contact_title: "Kontaktiere uns",
            navigation_title: "Navigationsleiste ",
            location: `Fatih Mahallesi 1196 Sokak
            No: 3 Sarnıç / İZMİR / TURKEI`,
            footer_xd: "Es ist ein Tochterunternehmen von Inka Plastik.",
          },
          product_section: {
            title: "PRODUKTE",
            button: "Details anzeigen",
            products: [
              {
                title: "LDPE-Folie",
                desc: `Low Density Polyethylen
                (LDPE) ist eine Art thermoplastischer Kunststoff, der aus Erdöl hergestellt wird. Er ist flexibel 
                und transparent. LDPE-FILM-Produkte sind dank ihrer zahlreichen Vorteile zu einem der 
                beliebtesten Verpackungsmaterialien geworden.`,
                product_img: require("./images/lpdeFlim.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `-Filmbreite: 20 cm - 300 cm`,
                  `-Filmdicke: 30 mic - 200 mic`,
                  `-Es ist wasserfest.`,
                  `-Schützt vor Feuchtigkeit und Staub.`,
                  "-Es hat eine hohe thermische Adhäsion.",
                ],
              },
              {
                title: "LDPE-Schrumpffolie",
                desc: `Schrumpffolien schrumpfen bei Wärmeeinwirkung fest 
                über alles, was sie bedecken, oder das Produkt und die Folie können auf einem Förderband durch einen Wärmetunnel geführt werden.`,
                product_img: require("./images/Shrink.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "Designed by upklyak / Freepik",
                details: [
                  `-Filmbreite: 20 cm - 300 cm`,
                  `-Filmdicke: 30 mic - 200 mic`,
                  `-Die Dicke der Folie kann je nach Gewicht der zu verpackenden Ladung, Verwendungs- und Lagerbedingungen variieren.`,
                ],
              },
              {
                title: "Palettenabdeckung",
                desc: `Abdeckfolie wird verwendet, um alle Arten von palettierten 
                Produkten vor schädlichen Witterungseinflüssen wie Sonne, Regen und Staub zu schützen.
                Sie ist elastisch und belastbar, wickelt scharfe Kanten ohne zu reißen.`,
                product_img: require("./images/palletcover.jpg"),
                product_img_alt:
                  "https://www.freepik.com/free-photo/creative-artisan-job-workshop_14315171.htm#query=Pallet%20coverd&position=18&from_view=search&track=ais%22%3EFreepik",
                product_img_alt_author: "Freepik",

                details: [
                  `Es wird aus Polyethylen niedriger Dichte hergestellt. (LDPE)`,
                  `Mit UV-Additiv wird eine hohe Haltbarkeit und lange Lebensdauer bei der Lagerung erreicht.`,
                  `-Filmbreite: 50 cm - 300 cm .`,
                  `-Filmdicke: 30mic - 200mic .`,
                  `-Balg: 5 cm – 70 cm`,
                ],
              },

              {
                title: "Müllsäcke",
                desc: "Schützt die Produkte vor äußeren Witterungseinflüssen.",
                product_img: require("./images/garbagebag.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `Wir produzieren Industriemüllsäcke in verschiedenen Größen und Farben.`,
                ],
              },
              {
                title: "Tragetaschen",
                desc: "Schützt die Produkte vor äußeren Witterungseinflüssen.",
                product_img: require("./images/carrybag.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `Wir produzieren Industriemüllsäcke in verschiedenen Größen und Farben.`,
                ],
              },
              {
                title: "Matratzenfolie",
                desc: "Lässt sich wie ein Bezug über die Matratze ziehen und kann von einer Seite geklebt, verschlossen werden.",
                product_img: require("./images/147.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "",
                details: [
                  `Schützen Sie Ihre Matratze während des Transports vor Staub, Schmutz, Insekten und Verschüttungen.
                  `,
                  ` Je nach Größe des Produkts 
                  können perforierte Seitenfaltenbeutel mit automatischen Maschinen hergestellt werden.`,
                ],
              },
              {
                title: "Industrielle Beutel",
                desc: `Industrielle PE-Säcke werden in vielen Bereichen als  Sonderanfertigung der gewünschten Eigenschaften und Größen produziert.`,
                product_img: require("./images/147.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "",
                details: [
                  `Der 
                  industrielle Beutel wird verwendet, um das Produkt im Inneren vor Feuchtigkeit und Wasser 
                  zu schützen.`,
                ],
              },
            ],
          },
        },
      },
      tr: {
        translation: {
          modal_title: "ÖLÇÜLER ve ÖZELLİKLER",
          company_slogan: "Güvenilir Ürünler",
          modal_button: "Kapat",
          navbar: {
            home: "Anasayfa",
            about_us: "Hakkımızda",
            products: "Ürünlerimiz",
            works: "Çalışmalarımız",
          },
          about_section: {
            title: "HAKKIMIZDA",
            desc: "Libertepack  2018 yılında plastik ambalaj alanında üretici ve tedarikçi olarak hizmet vermeye başlamıştır. ITOB organize sanayi bölgesinde bulunan tesis, 1000 metre karelik kapalı alana sahip olup faaliyetlerini sürdürmektedir. Yıllık yaklaşık 1000 ton LPDE, HDPE plastik ambalaj üretimi ve temini sağlamaktadır.",
          },
          footer: {
            contact_title: "İletişim",
            navigation_title: "Navigasyon",
            location: `Fatih Mahallesi 1196 Sokak
            No: 3 Sarnıç / İZMİR / TÜRKİYE`,
            footer_xd: "Bir Inka Plastik kuruluşudur.",
          },
          product_section: {
            title: "ÜRÜNLER",
            button: "Detaylar",
            products: [
              {
                title: "LDPE Film",
                desc: "Alçak Yoğunluklu Polietilen (LDPE), petrolden elde edilen bir termoplastik türüdür. Esnek ve şeffaftır. LDPE FİLM ürünleri sunduğu çeşitli avantajlar sayesinde en çok tercih edilen ambalaj malzemelerinden biri haline gelmiştir.",
                product_img: require("./images/lpdeFlim.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `-Film Genişliği: 20 cm - 300 cm arasıdır.`,
                  `-Film Kalınlığı: 30 mic - 200 mic arasıdır.`,
                  `-Suya dayanıklıdır.`,
                  `-Eşyalarınızı nem ve tozdan korur.`,
                  "-Yüksek termal yapışma seviyesine sahiptir.",
                ],
              },
              {
                title: "LDPE Shrink Film",
                desc: "Shrink filmler, ısı uygulandığında üzerini kaplayan her şeyin üzerinde sıkıca büzülür. Isı, el tipi bir ısı tabancasıyla uygulanabilir veya ürün ve film, bir konveyör üzerindeki bir ısı tünelinden geçebilir.",
                product_img: require("./images/Shrink.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "Designed by upklyak / Freepik",
                details: [
                  `-Film Genişliği: 20 cm - 300 cm arasıdır.`,
                  `-Film Kalınlığı: 30 mic - 200 mic arasıdır.`,
                  `-Filmin kalınlığı paketlenecek yükün ağırlığına, kullanım ve saklama koşullarına göre değişiklik gösterebilir.`,
                ],
              },
              {
                title: "Palet Örtüsü",
                desc: "Paletlenmiş her türlü ürünü güneş, yağmur, toz gibi havanın zararlı etkilerinden korumak için kullanılır. Esnek ve güçlüdür, keskin kenarları yırtılmadan sarar.",
                product_img: require("./images/palletcover.jpg"),
                product_img_alt:
                  "https://www.freepik.com/free-photo/creative-artisan-job-workshop_14315171.htm#query=Pallet%20coverd&position=18&from_view=search&track=ais%22%3EFreepik",
                product_img_alt_author: "Freepik",

                details: [
                  `Alçak yoğunluklu polietilenden (LDPE) üretilir.`,
                  `UV katkısı ile depolamada yüksek dayanıklılık ve uzun ömür elde edilir.`,
                  `- Film Genişliği: 20 cm - 300 cm`,
                  `-Film Kalınlığı: 30 mic - 200 mic.`,
                  `-Körük: 5cm ile 70cm arası`,
                ],
              },

              {
                title: "Çöp Torbası",
                desc: "Ürünleri dış hava şartlarına karşı korur.",
                product_img: require("./images/garbagebag.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `Çeşitli boyutlar ve renklerde çöp torbaları üretiyoruz.`,
                ],
              },
              {
                title: "Taşıma Torbası",
                desc: "Ürünleri dış hava koşullarına karşı korur.",
                product_img: require("./images/carrybag.jpg"),
                product_img_alt: "https://unsplash.com/",
                product_img_alt_author: "Photo by Jeremy Bishop on Unsplash",
                details: [
                  `Çeşitli boyutlar ve renklerde taşıma  torbaları üretiyoruz.`,
                ],
              },
              {
                title: "Yatak Naylonu",
                desc: "Şiltenin üzerinden yastık kılıfı gibi kayar ve bantla kapatılabilir.",
                product_img: require("./images/147.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "",
                details: [
                  `Taşıma sırasında yatağınızı toz, kir, böcek ve dökülmelerden korur.`,
                  ` Ürünün ebatlarına bağlı olarak otomatik makineler ile perforeli körüklü torbalar
                  üretilebilmektedir.`,
                ],
              },
              {
                title: "Endüstriyel Torba",
                desc: `Endüstriyel PE torbalar özel üretim olması nedeni ile bir çok alanda tercih edilmektedir.
                siparişe göre istenilen özellik ve ölçülerde yapılmaktadır.`,
                product_img: require("./images/147.jpg"),
                product_img_alt: "http://www.freepik.com",
                product_img_alt_author: "",
                details: [
                  `Endüstriyel torba, içindeki ürünü nem ve suya karşı korumak amacıyla kullanılır.`,
                ],
              },
            ],
          },
        },
      },
    },
  });

export default i18n;
