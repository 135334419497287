import React from "react";
import "./hero.css";
import World from "../videos/World.mp4";
import { useTranslation } from "react-i18next";
const Hero = () => {
  const { t } = useTranslation();
  return (
    <div className="hero-div">
      <video src={World} autoPlay loop muted className="hero-video"></video>
      <div className="content">
        <h1 className="brand-title">LIBERTEPACK</h1>
        <h3 className="brand-slogan">{t("company_slogan")}</h3>
      </div>
    </div>
  );
};

export default Hero;
