import React from "react";
import { Card, Button } from "react-bootstrap";
import "./card.css";
import { useTranslation } from "react-i18next";
const ReusableCard = (props) => {
  const { t } = useTranslation();
  return (
    <div className="card-container container-fluid">
      <Card className="card-classic  ">
        <a
          style={{ display: "none", visibility: "hidden" }}
          href={props.info.product_img_alt}
        >
          {props.info.product_img_alt_author}
        </a>
        <img
          className="card-img-top"
          style={{ height: 200, width: "100%", objectFit: "cover" }}
          alt="img-card"
          src={props.info.product_img}
        />
        <Card.Body style={{ backgroundColor: "#fff" }}>
          <Card.Title style={{ color: "#011627" }}>
            {props.info.title}
          </Card.Title>
          <Card.Text>{props.info.desc}</Card.Text>
          <Button
            variant="primary"
            onClick={() => {
              props.handleModal({
                title: props.info.title,
                desc: props.info.desc,
                img: props.info.product_img,
                details: props.info.details,
              });
            }}
          >
            {t("product_section.button")}
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ReusableCard;
