import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Product from "./components/Product";
import Footer from "./components/Footer";
import { useState } from "react";
import Modal from "./components/Modal";
import Gallery from "./components/Gallery";
function App() {
  const [product_info, setProudct] = useState({});
  const [show, setShow] = useState(false);
  const [pause, setPause] = useState(5000);
  const handleShow = (info) => {
    if (info !== "") {
      setProudct(info);
      setShow(true);
      setPause(null);
    }
  };

  const closeModel = () => {
    setShow(false);
    setPause(3000);
  };
  return (
    <div className="App" data-bs-spy="scroll" data-bs-target="#navnav">
      <Modal show={show} info={product_info} close={closeModel} />
      <Navbar handleModal={handleShow} />
      <section id="home" className="hero-section ">
        <Hero />
      </section>
      <section id="about" className="about-section">
        <About />
      </section>
      <section id="products" className="product-section">
        <Product handleModal={handleShow} pause={pause} />
      </section>
      <section id="gallery" className="gallery-section">
        <Gallery />
      </section>
      <section className="footer-section">
        <Footer />
      </section>
    </div>
  );
}
export default App;
