import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./product.css";
import ReusableCard from "./ReusableCard";
import { useTranslation } from "react-i18next";

function ControlledCarousel(props) {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();
  const array_of_products = t("product_section.products", {
    returnObjects: true,
  });

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="slayt">
      <h1>{t("product_section.title")}</h1>
      <Carousel
        pause="hover"
        activeIndex={index}
        onSelect={handleSelect}
        className="carousel"
        interval={props.pause}
      >
        {array_of_products.map((element) => {
          return (
            <Carousel.Item key={element.title} className="carousel-item">
              <ReusableCard handleModal={props.handleModal} info={element} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}
export default ControlledCarousel;
