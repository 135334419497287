import Modal from "react-bootstrap/Modal";
import "./modal.css";
import { useTranslation } from "react-i18next";
function Example(props) {
  const modal_state = props.show;
  const handleClose = () => {
    props.close(false);
  };
  let index = 0;
  const { t } = useTranslation();
  return (
    <>
      <Modal scrollable className="product-modal  " centered show={modal_state}>
        <Modal.Header>
          <img
            src={props.info.img}
            style={{
              maxHeight: 300,
              width: "100%",
              objectFit: "cover",
              padding: 0,
            }}
            alt="..."
          />
        </Modal.Header>
        <Modal.Title className="title" style={{ textAlign: "center" }}>
          {props.info.title}
        </Modal.Title>
        <Modal.Body className="modal-dialog-scrollable  ">
          <div className="details " style={{ textAlign: "center" }}>
            {modal_state &&
              props.info.details.map((Element) => {
                index++;
                return <p key={index}>{Element}</p>;
              })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            {t("modal_button")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;
