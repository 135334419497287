import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./navbar.css";
import LanguageSelectionBox from "./LanguageSelectionBox";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function BasicExample(props) {
  const { t } = useTranslation();
  const array_of_products = t("product_section.products", {
    returnObjects: true,
  });
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);
  return (
    <Navbar
      id="navnav"
      fixed="top"
      className={color ? "navbr navbar-bg" : "navbr"}
      variant="dark"
      expand="lg"
    >
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt="Logo.png"
            src={require("../images/logo.png")}
            width="110"
            height="110"
          />
          LIBERTEPACK
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="view">
          <Nav className="">
            <Nav.Link href="#home">{t("navbar.home")}</Nav.Link>
            <Nav.Link href="#about">{t("navbar.about_us")}</Nav.Link>
            <Nav.Link href="#gallery">{t("navbar.works")}</Nav.Link>
            <NavDropdown
              menuVariant="dark"
              href="#products"
              title={t("navbar.products")}
              id="basic-nav-dropdown"
            >
              {array_of_products.map((element) => {
                return (
                  <NavDropdown.Item
                    key={element.title}
                    onClick={() => {
                      props.handleModal({
                        title: element.title,
                        desc: element.desc,
                        img: element.product_img,
                        details: element.details,
                      });
                    }}
                  >
                    {element.title}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          </Nav>
          <LanguageSelectionBox className="selector" />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
