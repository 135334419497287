import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer-clean">
      <footer>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-sm-4 col-md-4 item">
              <h3>{t("footer.navigation_title")}</h3>
              <ul>
                <li>
                  <a href="#home">{t("navbar.home")}</a>
                </li>
                <li>
                  <a href="#about">{t("navbar.about_us")}</a>
                </li>
                <li>
                  <a href="#products">{t("navbar.products")}</a>
                </li>
                <li>
                  <a href="#works">{t("navbar.works")}</a>
                </li>
              </ul>
            </div>

            <div className="col-sm-4 col-md-4 item">
              <h3>{t("footer.contact_title")}</h3>
              <ul>
                <li>ozgur.yozgat@libertepack.com</li>
                <li>serpil.yozgat@libertepack.com</li>
                <li>+90 544 779 9498</li>
                <li>{t("footer.location")}</li>
              </ul>
            </div>
            <div className="col-sm-4 col-md-4 item copyright-div ">
              <a className="logo-link" href="http://www.inkaplastik.com.tr/">
                <img
                  width="130"
                  height="49"
                  src={require("../images/inka.png")}
                  alt="inka.png"
                />
              </a>
              <p className="copyright">{t("footer.footer_xd")}</p>
              <p className="copyright">Libertepack © 2022</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
