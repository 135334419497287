import Carousel from "react-bootstrap/Carousel";
import "./Gallery.css";
import { useTranslation } from "react-i18next";
function CarouselFadeExample() {
  const urls = [];
  const length = 20;
  for (var i = 1; i < length; i++) {
    urls[i - 1] = require(`../images/works/${i}.jpg`);
  }
  console.log(urls);
  const { t } = useTranslation();
  return (
    <div className="gallery-container">
      <h1>{t("navbar.works").toUpperCase()}</h1>
      <Carousel className="img-shower carousel">
        {urls.map((e) => {
          return (
            <Carousel.Item>
              <div className="card-container container-fluid">
                <img
                  style={{ height: 500, width: 700, objectFit: "cover" }}
                  className="d-block"
                  src={e}
                  alt="First slide"
                />
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default CarouselFadeExample;
